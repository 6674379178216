export enum AppStatus {
  INIT = 'init',
  PENDING = 'pending',
  ACTIVE = 'active',
  ERROR = 'error'
}

export enum Process {
  INIT = 'init',
  BUY = 'buy',
  SELL = 'sell'
}

export enum ProcessStatus {
  INIT = 'init',
  INVESTED = 'invested',
  WITHDRAWN = 'withdrawn'
}

export enum Token {
  BITCOIN = 'bitcoin',
  ETHEREUM = 'ethereum',
  LITECOIN = 'litecoin',
  AVALANCHE = 'avalanche-2',
  SOLANA = 'solana',
  NEAR = 'near',
  ARBITRUM = 'arbitrum',
  COSMOS = 'cosmos',
  APTOS = 'aptos',
  CHAINLINK = 'chainlink',
  // 10
  POLKADOT = 'polkadot',
  INJECTIVE = 'injective-protocol',
  ALGORAND = 'algorand',
  GRAPH = 'the-graph',
  FILECOIN = 'filecoin',
  STARKNET = 'starknet',
  POLYGON = 'matic-network',
  MULTIVERSX = 'elrond-erd-2',
  HEDERA = 'hedera-hashgraph',
  OSMOSIS = 'osmosis',
  // 20
  MINA = 'mina-protocol',
  AXIE = 'axie-infinity',
  TRUSTWALLET = 'trust-wallet-token',
  DOGECOIN = 'dogecoin',
  UNISWAP = 'uniswap',
  GMX = 'gmx',
  FLOW = 'flow',
  RONIN = 'ronin',
  JASMY = 'jasmycoin',
  CARDANO = 'cardano',
  // 30
  ETHEREUM_CLASSIC = 'ethereum-classic',
  SHIBA = 'shiba-inu',
  SUSHI = 'sushi',
  BOOK_OF_MEME = 'book-of-meme',
  SAND = 'the-sandbox',
  RAYDIUM = 'raydium',
  RIPPLE = 'ripple',
  SUI = 'sui',
  CETUS_PROTOCOL = 'cetus-protocol',
  BAND_PROTOCOL = 'band-protocol',
  // 40
  AUTOMATA = 'automata',
  MOBOX = 'mobox',
  AAVE = 'aave',
  APE_COIN = 'apecoin',
  THETA = 'theta-token',
  COMPOUND_GOVERNANCE = 'compound-governance-token',
  SYNTHETIX_NETWORK = 'havven',
  PANCAKE_SWAP = 'pancakeswap-token',
  DIA = 'dia-data',
  ONE_INCH = '1inch',
  // 50
  MANTA_NETWORK = 'manta-network',
  REEF = 'reef',
  NEO = 'neo',
  NERVOS_NETWORK = 'nervos-network',
  KUSAMA = 'kusama',
  STELLAR = 'stellar',
  RADIANT_CAPITAL = 'radiant-capital',
  YEARN_FINANCE = 'yearn-finance',
  INTERNET_COMPUTER = 'internet-computer',
  WORMHOLE = 'wormhole',
  // 60
  ALIEN_WORLDS = 'alien-worlds',
  JUPITER = 'jupiter',
  SAFEPAL = 'safepal',
  ONTOLOGY = 'ontology',
  VERGE = 'verge',
  BISWAP = 'biswap',
  KAVA = 'kava',
  MY_NEIGHBOR_ALICE = 'my-neighbor-alice',
  VANAR_CHAIN = 'vanar-chain',
  MINES_OF_DALARNIA = 'mines-of-dalarnia',
  // 70
  THETA_FUEL = 'theta-fuel',
  ASTAR = 'astar',
  DODO = 'dodo',
  CHIA = 'chia',
  LINEAR = 'linear',
  LITENTRY = 'litentry',
  MOONBEAM = 'moonbeam',
  RAVENCOIN = 'ravencoin',
  CELO = 'celo',
  ANKR = 'ankr',
  // 80
  OASIS_NETWORK = 'oasis-network',
  DECENTRALAND = 'decentraland',
  ZILLIQA = 'zilliqa',
  EOS = 'eos',
  EVMOS = 'evmos',
  IOTA = 'iota'
}

export enum Symbol {
  BTC = 'btc',
  ETH = 'eth',
  LTC = 'ltc',
  AVAX = 'avax',
  SOL = 'sol',
  NEAR = 'near',
  ARB = 'arb',
  ATOM = 'atom',
  APT = 'apt',
  LINK = 'link',
  // 10
  DOT = 'dot',
  INJ = 'inj',
  ALGO = 'algo',
  GRT = 'grt',
  FIL = 'fil',
  STRK = 'strk',
  MATIC = 'matic',
  EGLD = 'egld',
  HBAR = 'hbar',
  OSMO = 'osmo',
  // 20
  MINA = 'mina',
  AXS = 'axs',
  TWT = 'twt',
  DOGE = 'doge',
  UNI = 'uni',
  GMX = 'gmx',
  FLOW = 'flow',
  RON = 'ron',
  JASMY = 'jasmy',
  ADA = 'ada',
  // 30
  ETC = 'etc',
  SHIBA = 'shiba',
  SUSHI = 'sushi',
  BOME = 'bome',
  SAND = 'sand',
  RAY = 'ray',
  XRP = 'xrp',
  SUI = 'sui',
  CETUS = 'cetus',
  BAND = 'band',
  // 40
  ATA = 'ata',
  MBOX = 'mbox',
  AAVE = 'aave',
  APE = 'ape',
  THETA = 'theta',
  COMP = 'comp',
  SNX = 'snx',
  CAKE = 'cake',
  DIA = 'dia',
  ONE_INCH = '1inch',
  // 50
  MANTA = 'manta',
  REEF = 'reef',
  NEO = 'neo',
  CKB = 'ckb',
  KSM = 'ksm',
  XLM = 'xlm',
  RDNT = 'rdnt',
  YFI = 'yfi',
  ICP = 'icp',
  W = 'w',
  // 60
  TLM = 'tlm',
  JUP = 'jup',
  SFP = 'sfp',
  ONT = 'ont',
  XVG = 'xvg',
  BSW = 'bsw',
  KAVA = 'kava',
  ALICE = 'alice',
  VANRY = 'vanry',
  DAR = 'dar',
  // 70
  TFUEL = 'tfuel',
  ASTR = 'astr',
  DODO = 'dodo',
  XCH = 'xch',
  LINA = 'lina',
  LIT = 'lit',
  GLMR = 'glmr',
  RVN = 'rvn',
  CELO = 'celo',
  ANKR = 'ankr',
  // 80
  ROSE = 'rose',
  MANA = 'mana',
  ZIL = 'zil',
  EOS = 'eos',
  EVMOS = 'evmos',
  IOTA = 'iota'
}
